<template>
  <v-app>
    <div>
      <b-form @submit.stop.prevent="formOnsubmit">

        <!-- Input  Action Type-->
        <b-form-group
          id="input-group-package-name"
          label="Nama Sortcut:"
          label-for="input-package-name"
        >
          <b-form-input
              id="input-package-medicine"
              v-model="form.package_name"
              placeholder="Nama Sortcut"
            >
            </b-form-input>
          <small class="text-danger">{{ error.package_name }}</small>
        </b-form-group>
        
        <!-- Input  Action Type-->
        <b-form-group
          id="input-group-action-type"
          label="Jenis Tindakan:"
          label-for="input-action-type"
        >
          <treeselect
            v-model="action_type"
            :multiple="false"
            :options="action_types"
          />
          <small class="text-danger">{{ error.action_type }}</small>
        </b-form-group>

        <!-- Input Anesthesia -->
        <!-- <b-form-group
          id="input-group-anesteshia"
          label="Anastesi:"
          label-for="input-anesteshia"
        >
          <b-form-input
            id="input-anesteshia"
            v-model="form.anesteshia"
            placeholder="Anestesi"
          ></b-form-input>
          <small class="text-danger">{{ error.anesteshia }}</small>
        </b-form-group> -->
       

        <!-- Input Modal Trigger -->
        <b-form-group id="input-group-trigger-modal-medicine">
          <label for="input-trigger-modal-medicine"
            >Obat</label
          >
          <b-input-group>
            <b-form-input
              id="input-trigger-modal-medicine"
              v-model="display.medicine_name"
              placeholder="Pilih Obat dan Alkes"
              readonly
              @click="$bvModal.show('modal-medicine')"
              style="border-right: none;"
            >
            </b-form-input>
            <template #append>
              <b-input-group-text
                squared
                :class="btnClearMedicineClass"
                style="border-left: none"
                @click="resetMedicine"
                @mouseover="btnClearMedicineClass = 'bg-light-danger'"
                @mouseout="btnClearMedicineClass = 'bg-white'"
              >
                <i class="fas fa-sm fa-times text-danger"></i>
              </b-input-group-text>
            </template>
          </b-input-group>
          <small class="text-danger">{{ error.medicines }}</small>
        </b-form-group>

        <div class="bg-light" v-if="coll_medicine.length > 0">
        <table class="table" v-if="coll_medicine.length > 0">
          <tr>
            <th>Nama</th>
            <th>Kuantitas</th>
            <th>Harga Jual</th>
            <th>Total Harga</th>
            <td>Aksi</td>
          </tr>
          <tr v-for="mdc in coll_medicine" :key="mdc.id">
            <td>{{ mdc.name }}</td>
            <td>{{ mdc.quantity }}</td>
            <td>{{ mdc.sales_price }}</td>
            <td>{{ mdc.total_price }}</td>
            <td>
                <b-button
                  size="sm"
                  class="btn-danger"
                  v-b-tooltip.hover.left
                  title="Hapus Obat dan Alkes"
                  type="button"
                  @click="removeMedicine(mdc.id)"
                  ><i class="fas fa-trash px-0"></i
                ></b-button>
              </td>
          </tr>
        </table>
        </div>

      
        <!-- Submit & Cancel button -->
        <b-button
          type="submit"
          variant="primary"
        >Simpan</b-button>
        <b-button
          type="button"
          class="ml-2"
          variant="default"
          @click="$router.push('/medicine-packages')"
        >
          Batal
        </b-button>
      </b-form>

<!-- Input Medicine -->
      <b-modal
          ref="my-modal"
          hide-footer
          title="Resep Obat"
          @hide="hideModalCancel"
        >
          <b-form @submit.stop.prevent="medicineFormOnsubmit">
            <!-- <div class="d-block text-center"> -->
            <b-form-group
              id="input-group-name"
              label="Nama:"
              label-for="input-name"
            >
              <b-form-input
                id="input-name"
                v-model="coll_medicine_form.name"
                placeholder="Nama obat"
                readonly
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="input-group-quantity"
              label="Kuantitas:"
              label-for="input-quantity"
            >
              <b-form-input
                id="input-quantity"
                v-model="coll_medicine_form.quantity"
                placeholder="Kuantitas"
                required
              ></b-form-input>
            </b-form-group>
            <!-- </div> -->
            <!-- Submit & Cancel button -->
            <b-button type="submit" variant="primary">Simpan</b-button>
            <b-button
              type="button"
              class="ml-2"
              variant="default"
              @click="hideModalCancel"
            >
              Cancel
            </b-button>
          </b-form>
        </b-modal>

   
      <ModalMedicine
        @chosenMedicine="setPrescription"
        :selectedItem="coll_medicine"
        :checkbox="true"
      />
    </div>
  </v-app>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";

import ModalMedicine from "@/component/general/ModalMedicine.vue"
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import ApiService from "@/core/services/api.service.js";

export default {
  props: {
    form: Object,
    route: String,
  },

  components: {
   
    ModalMedicine,
  },

  data() {
    return {
      modal2: false,
      // Display Data
      display: {
        patient_name: "",
        assistant_name: "",
        medicine_name: "",
      },
      file_name: [],
      // Error
      error: {
        patient_id: null,
        patient_name: "",
        patient_age: "",
        electro_laser: "",
        action_type: "",
        operator: "",
        anesteshia: "",
        anesthetist: null,
        notes: "",
        date: "",
        package_name:"",
      },
      doctors: [],
      action_types: [],
      anesteshia: [
        { text: "Lokal", value: "Lokal" },
        { text: "Sedasi", value: "Sedasi" },
        { text: "Spinal", value: "Spinal" },
        { text: "Umum", value: "Umum" },
      ],
      operator: [],
      anesthetist: [],
      action_type: [],
      package_name:"",
      //   set input
      // Operator
      coll_operator_actv: [],
      coll_operator_form: {
        id: "",
        name: "",
      },
      coll_operator: [],
      coll_operator_str: "",
      // Anesthetist
      coll_anesthetist_actv: [],
      coll_anesthetist_form: {
        id: "",
        name: "",
      },
      coll_anesthetist: [],
      coll_anesthetist_str: "",
      // Action Type
      coll_action_type_actv: [],
      coll_action_type_form: {
        id: "",
        name: "",
      },
      coll_action_type: [],
      coll_action_type_str: "",
      // Assistant
      coll_assistant_actv: [],
      coll_assistant_form: {
        id: "",
        name: "",
        position: "",
      },
      coll_assistant: [],
      coll_assistant_str: "",
      // Medicine
      coll_medicine_actv: [],
      coll_medicine_form: {
        id: "",
        name: "",
        sales_price: "",
        total_price: "",
      },
      coll_medicine: [],
      coll_medicine_str: "",
      //
      uploadAttachmentURL: "",
      // data
      formData: new FormData(),
      attachment: "",
      // other
      btnClearMedicineClass: 'bg-white',
      medicine_collection_submit: false,
      medicine_id: "",
      medicine: "",
      name_medicine: "",
      // Pagination
      perPage: 10,
      currentPage: 1,
      totalRows: 1,
      // Table Head
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "date",
          label: "Tanggal",
          sortable: true,
        },
        {
          key: "patient_name",
          label: "Pasien",
          sortable: true,
        },
        {
          key: "action_type",
          label: "Diagnosa Tindakan",
          sortable: true,
        },
        {
          key: "operator",
          label: "Operator",
          sortable: true,
        },
        {
          key: "assistant",
          label: "Asisten",
          sortable: true,
        },
        {
          key: "anesteshia",
          label: "Anastesi",
          sortable: true,
        },
        {
          key: "anesthetist",
          label: "Dokter Anastesi",
          sortable: true,
        },
        {
          key: "medicines",
          label: "Obat/Alkes",
          sortable: true,
        },
      ],
      // Table Items
      items: [],
    };
  },

  methods: {
    hideModal() {
      this.$refs["my-modal"].hide();
    },

    async hideModalCancel() {
      if (this.medicine_collection_submit == false) {
        
        // this.medicine_id.pop();
      }
      await this.$refs["my-modal"].hide();
      this.setMedicineSubmit();
    },

    setMedicineSubmit() {
      this.medicine_collection_submit = false;
    },

    async pagination(id) {
      let filterParams = ``;
      let response = await module.paginate(
        "medical-actions/get-by-patient/" + id,
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;

      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        let date = new Date(response.data[a].date)
        let convert = date.toLocaleString().split(",")[0]
        response.data[a].number = b + a;
        response.data[a].date = convert
        
      }

      this.items = response.data;
      
      this.$bvModal.hide("modal-patient");

      
    },

    setPatient(value) {
      this.form.patient_id = value.id;
      this.display.patient_name = value.name;
      this.pagination(value.id);
    },

    setAssistant(value) {
      if(this.coll_assistant.length > 0){
      let a
      for(a = 0; a < this.coll_assistant.length > 0; a++){
        if(this.coll_assistant[a].id == value.id){
          this.coll_assistant.splice(a, 1);
          
          if(this.coll_assistant.length == 0){
            this.display.assistant_name = ""
            break;
          }else if(this.coll_assistant.length > 0){
            this.displayAssistanName(this.coll_assistant)
            break;
          }
          break;
        }else if(a == this.coll_assistant.length - 1){
            this.display.assistant_name = value.name;
            this.coll_assistant_form.id = value.id;
            this.coll_assistant_form.name = value.name;
            this.coll_assistant_form.position = value.position;

            let clone = { ...this.coll_assistant_form };
            this.coll_assistant.push(clone);
            
            this.displayAssistanName(this.coll_assistant);
          break;
        }
      }
      }else{
            this.display.assistant_name = value.name;
            this.coll_assistant_form.id = value.id;
            this.coll_assistant_form.name = value.name;
            this.coll_assistant_form.position = value.position;

            let clone = { ...this.coll_assistant_form };
            this.coll_assistant.push(clone);
            
            this.displayAssistanName(this.coll_assistant);
      }
    },

    resetAssistant() {
      this.coll_assistant.splice(0, this.coll_assistant.length);
      
      this.display.assistant_name = "";
    },

    // setMedicine(value) {
    //   let a;
    //   if (this.coll_medicine.length > 0) {
    //     for (a = 0; a < this.coll_medicine.length; a++) {
    
    
    //       if (this.coll_medicine[a].id == value.id) {
    
    
    //         Swal.fire("Gagal", "Obat dan Alkes tersebut telah dipilih", "warning");
    //         break;
    //       } else if (a == this.coll_medicine.length - 1) {
    //         // this.$bvModal.hide("modal-medicine");

    //         this.display.medicine_name = value.name;
    //         this.coll_medicine.id = value.id;
    //         this.coll_medicine.name = value.name;
    //         this.coll_medicine.sales_price = value.sales_price;

    //         let clone = { ...this.coll_medicine };
    //         this.coll_medicine.push(clone);
    
    //         this.displayMedicineName(this.coll_medicine);
    //         break;
    //       }
    //     }
    //   } else {
    //     this.display.medicine_name = value.name;
    //     this.coll_medicine.id = value.id;
    //     this.coll_medicine.name = value.name;
    //     this.coll_medicine.sales_price = value.sales_price;

    //     let clone = { ...this.coll_medicine };
    //     this.coll_medicine.push(clone);
    

    //     this.displayMedicineName(this.coll_medicine);

    //     // this.$bvModal.hide("modal-medicine");
    //   }
    // },

    async setPrescription(value) {
      
      if (this.coll_medicine.length > 0) {
        let a;
        for (a = 0; a < this.coll_medicine.length; a++) {
          if (this.coll_medicine[a].id == value.id) {
            this.coll_medicine.splice(a, 1);
            if (this.coll_medicine.length == 0) {
              this.display.medicine_name = "";
              break;
            } else if (this.coll_medicine.length > 0) {
              this.displayMedicineName(this.coll_medicine);
              break;
            }
            break;
          } else if (a == this.coll_medicine.length - 1) {
            this.$bvModal.hide("modal-medicine");
            
            await this.medicine_id;
            this.medicine_id = value.id;
            
            // this.coll_medicine_form.id = "";
            this.coll_medicine_form.name = "";
            this.coll_medicine_form.sales_price = "";
            this.coll_medicine_form.quantity = "";
            this.setMedicine(value.id);
          }
        }
      } else {
        this.$bvModal.hide("modal-medicine");
        
        await this.medicine_id;
        this.medicine_id = value.id;
        
        // this.coll_medicine_form.id = "";
            this.coll_medicine_form.name = "";
            this.coll_medicine_form.sales_price = "";
            this.coll_medicine_form.quantity = "";
        this.setMedicine(value.id);
      }
    },

    setMedicine(id) {
      ApiService.get("medicines/" + id).then((response) => {
        
        this.medicine = response.data.data;
        this.name_medicine = response.data.data.name;
        this.coll_medicine_form.name = response.data.data.name;
        this.coll_medicine_form.id = response.data.data.id;
        this.coll_medicine_form.sales_price = response.data.data.sales_price;
        this.$refs["my-modal"].show();
      });
    },

    displayMedicineName(medicine) {
      if (medicine.length == 0) {
        this.display.medicine_name = "";
      } else {
        let str = "";
        medicine.forEach(function (value, index) {
          str += value.name;
          if (index != medicine.length - 1) {
            if (medicine.length > 1) str += ", ";
          }
        });
        this.display.medicine_name = str;
      }
    },

    resetMedicine() {
      this.coll_medicine.splice(0, this.coll_medicine.length);
      
      this.display.medicine_name = "";
    },

    removeMedicine($id) {
      
      let a;
      for (a = 0; a < this.coll_medicine.length; a++) {
        if (this.coll_medicine[a].id == $id) {
          this.coll_medicine.splice(a, 1);
          
        }
      }
      this.displayMedicineName(this.coll_medicine);
    },

    medicineFormOnsubmit() {
      
      
      this.coll_medicine_form.total_price = parseInt(this.coll_medicine_form.quantity) * parseInt(this.coll_medicine_form.sales_price);
      this.coll_medicine_form.total_price = parseInt(this.coll_medicine_form.total_price).toLocaleString("id-ID");
      this.coll_medicine_form.sales_price = parseInt(this.coll_medicine_form.sales_price).toLocaleString("id-ID");

      this.medicine_collection_submit = true;
      let clone = { ...this.coll_medicine_form };
      this.coll_medicine.push(clone);

      
      this.displayMedicineName(this.coll_medicine);
      this.hideModal();
    },

    onFileUpload(evt) {
      this.uploadAttachmentURL = URL.createObjectURL(evt.target.files[0]);
      
      this.form.attachment = evt.target.files[0];
      
    },

    upload(event) {
      if (event.target.files.length > 0) {
        this.formData = new FormData();
      }

      for (let a = 0; a <= event.target.files.length; a++) {
        this.formData.append("attachment[]", event.target.files[a]);
      }
    },

    deleteAttach(index) {
      this.attachment.splice(index, 1);
      this.file_name.splice(index, 1);
      
    },

    getOperatorActive() {
      ApiService.get("medical-actions/" + this.$route.params.id).then(
        (response) => {
          this.coll_operator_actv = JSON.parse(response.data.data.operator);
          this.coll_operator = JSON.parse(response.data.data.operator);
          this.display.patient_name = response.data.data.patient_name;
          
          this.pagination(response.data.data.patient_id)

          this.attachment = response.data.data.attachment_list.split(",");
          

          let b;
          for (b = 0; b < this.attachment.length; b++) {
            this.file_name.push(this.attachment[b].split("____")[1]);
            
          }

          
          

          let a;
          for (a = 0; this.coll_operator_actv.length; a++) {
            this.operator.push(this.coll_operator_actv[a].id);
            
            
            
          }
        }
      );
    },

    getAssistantActive() {
      ApiService.get("medical-actions/" + this.$route.params.id).then(
        (response) => {
          this.coll_assistant_actv = JSON.parse(response.data.data.assistant);
          this.coll_assistant = JSON.parse(response.data.data.assistant);

          this.displayAssistanName(JSON.parse(response.data.data.assistant));

          
          

          // let a;
          // for (a = 0; this.coll_assistant_actv.length; a++) {
          //   this.assistant.push(this.coll_assistant_actv[a].id);
          
          
          
          // }
        }
      );
    },

    displayAssistanName(assistant) {
      
      if (assistant.length == 0) {
        this.display.assistant_name = "";
      } else {
        let str = "";
        assistant.forEach(function (value, index) {
          str += value.name + `(${value.position})`;
          if (index != assistant.length - 1) {
            if (assistant.length > 1) str += ", ";
          }
        });
        this.display.assistant_name = str;
      }
    },

    getMedicineActive() {
      // console.log("form", this.form);
      // console.log(this.form.package_name);
      // this.form.package_name = this.package_name
      ApiService.get("medicine-packages/" + this.$route.params.id).then(
        (response) => {
          this.action_type = response.data.data.action_id
          this.coll_medicine_actv = JSON.parse(response.data.data.medicine);
          this.coll_medicine = JSON.parse(response.data.data.medicine);

          this.displayMedicineName(JSON.parse(response.data.data.medicine));

          
          

          // let a;
          // for (a = 0; this.coll_medicine_actv.length; a++) {
          //   // this.medicines.push(this.coll_medicine_actv[a].id);
          
          
          
          // }
        }
      );
    },

    displayMedicineName(medicine) {
      if (medicine.length == 0) {
        this.display.medicine_name = "";
      } else {
        let str = "";
        medicine.forEach(function (value, index) {
          str += value.name;
          if (index != medicine.length - 1) {
            if (medicine.length > 1) str += ", ";
          }
        });
        this.display.medicine_name = str;
      }
    },

    getActionTypeActive() {
      ApiService.get("medical-actions/" + this.$route.params.id).then(
        (response) => {
          this.coll_action_type_actv = JSON.parse(
            response.data.data.action_type
          );
          this.coll_action_type = JSON.parse(response.data.data.action_type);

          
          

          let a;
          for (a = 0; this.coll_action_type_actv.length; a++) {
            this.action_type.push(this.coll_action_type_actv[a].id);
            
            
            
          }
        }
      );
    },

    getAnthetistActive() {
      ApiService.get("medical-actions/" + this.$route.params.id).then(
        (response) => {
          this.coll_anesthetist_actv = JSON.parse(
            response.data.data.anesthetist
          );
          this.coll_anesthetist = JSON.parse(response.data.data.anesthetist);

          
          

          let a;
          for (a = 0; this.coll_anesthetist_actv.length; a++) {
            this.anesthetist.push(this.coll_anesthetist_actv[a].id);
            
            
            
          }
        }
      );
    },

    async getDoctorOption() {
      let response = await module.setDoctorTreeSelect("doctors");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.doctors = response.data;
      }
    },

    async getActionTypes() {
      let response = await module.setTreeSelect("payment-categories");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.action_types = response.data;
        this.action_types.unshift({
          label: "Pilih Jenis Tindakan",
          id: "",
          isDisabled: true,
        });
      }
    },

    getAge(dateString) {
      var today = new Date();
      var birthDate = new Date(dateString);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      
      this.patient_age = age;
      // return age;
    },

    setOperator(evt) {
      
      this.coll_operator_form.id = evt.id;
      this.coll_operator_form.name = evt.label;

      let clone = { ...this.coll_operator_form };
      this.coll_operator.push(clone);
      
    },

    removeOperator(event) {
      
      

      // var arr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0];

      // for (var i = 0; i < arr.length; i++) {
      //   if (arr[i] === 5) {
      //     arr.splice(i, 1);
      //   }
      // }

      let a;
      for (a = 0; a < this.coll_operator.length; a++) {
        if (event.id == this.coll_operator[a].id) {
          
          this.coll_operator.splice(a, 1);
        }
      }
    },

    setAnesthetist(evt) {
      
      this.coll_anesthetist_form.id = evt.id;
      this.coll_anesthetist_form.name = evt.label;

      let clone = { ...this.coll_anesthetist_form };
      this.coll_anesthetist.push(clone);
      
    },

    removeAnesthetist(event) {
      
      

      // var arr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0];

      // for (var i = 0; i < arr.length; i++) {
      //   if (arr[i] === 5) {
      //     arr.splice(i, 1);
      //   }
      // }

      let a;
      for (a = 0; a < this.coll_anesthetist.length; a++) {
        if (event.id == this.coll_anesthetist[a].id) {
          
          this.coll_anesthetist.splice(a, 1);
        }
      }
    },

    setActionType(evt) {
      
      this.coll_action_type_form.id = evt.id;
      this.coll_action_type_form.name = evt.label;

      let clone = { ...this.coll_action_type_form };
      this.coll_action_type.push(clone);
      
    },

    removeActionType(event) {
      
      

      // var arr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0];

      // for (var i = 0; i < arr.length; i++) {
      //   if (arr[i] === 5) {
      //     arr.splice(i, 1);
      //   }
      // }

      let a;
      for (a = 0; a < this.coll_action_type.length; a++) {
        if (event.id == this.coll_action_type[a].id) {
          
          this.coll_action_type.splice(a, 1);
        }
      }
    },

    

    async formOnsubmit() {
      
     

      this.coll_medicine_str = await JSON.stringify(this.coll_medicine);
      this.form.medicines = await this.coll_medicine_str;
      // this.form.package_name = this.package_name
      

      // let formData = new FormData();
      // for (const [key, value] of Object.entries(this.form)) {
      //   formData.append(key, value);
      // }
      // // Make Request
      // let response = await module.submit(formData, this.route);
      for (const [key, value] of Object.entries(this.form)) {
       
          this.formData.append(key, value);
       
        // else {
        //   formData.append(key, value);
        // }
        // this.formData.append(key, value);
      }
      // Make Request
      let response = await module.submit(this.formData, this.route);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        this.$router.push("/medicine-packages");
      }
    },
  },

  mounted() {
    this.getDoctorOption();
    this.getActionTypes();
    
    
    this.getMedicineActive();
  },

  watch: {
    "form.patient_name": function (newVal, oldVal) {
      this.display.patient_name = newVal;
    },
    "action_type": function (val) {
     this.form.action_id = val
     
    },
  },
};
</script>

<style>
</style>